import "core-js/stable";
import "regenerator-runtime/runtime";

import 'swiper/css/swiper.min';
import 'owl.carousel2/dist/assets/owl.carousel';
import 'lightcase/src/css/lightcase';
import '@fortawesome/fontawesome-free/css/all';
import '@fortawesome/fontawesome-free/js/all';

require("borano/lib/retinajs")
require("borano/lib/bootstrap")
// require("borano/lib/jquery-mobile")
// require("borano/lib/imagesloaded")
// require("borano/lib/owlcarousel")
// require("borano/lib/isotope-layout")
require("borano/lib/stellar")
require("borano/lib/scripts")
require("borano/main")
